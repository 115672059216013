import { onAuthStateChanged } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../assets/gpt.css";
import ChatContainer from "../components/ChatContainer";
import { auth } from "../utils/firebase";
import { Server } from "../utils/server";
import { RiRobot2Line } from "react-icons/ri";

function Gpt() {
  const nav = useNavigate();
  const [threadID, setThreadID] = useState();

  useEffect(() => {
    Server.getThreadID(async (data) => {
      const apiResponse = await data.json();
      setThreadID(apiResponse.threadID);
    });
  }, []);

  useEffect(() => {
    // 사용자 상태를 감시합니다.
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser === null || currentUser.emailVerified === false) {
        // 로그인하지 않은 상태거나, 이메일 인증되지 않은 사용자인 경우
        nav("/");
      }
    });

    // 컴포넌트가 언마운트될 때 구독을 해제합니다.
    return () => unsubscribe();
  }, []);

  if (threadID === undefined) {
    return <div>Loading...</div>;
  }
  return (
    <div className="gpt">
      <div className="gpt_left">
        <div className="gpt-nav">
          <RiRobot2Line className="YESBOT" />
          &nbsp; 양전자시스템 ChatBot
        </div>
        <ChatContainer threadID={threadID} />
      </div>
      <div className="gpt_right">
        <div className="guide-nav">
          <RiRobot2Line className="YESBOT" />
          &nbsp; 다음과 같이 질문해주세요
        </div>

        <div className="container">
          <div className="box">
            현재 YESBOT은 아래의 질문에 대답할수 있어요.
          </div>
          <div className="box">1. Linear Alarm 원인 & 해결 방법 </div>
          <div className="box" />
          <div className="box">2. 장비 Set-Up Manual </div>
          <div className="box"> - Align Recipe 작성 방법 </div>
          <div className="box"> - Probe Card Setting 방법 </div>
          <div className="box"> - Linear 부하율 Tuning </div>
          <div className="box"> - Linear Home Off-Set Tuning </div>
          <div className="box" />
          <div className="box">3. 신뢰성 장치 Calibration Manual </div>
          <div className="box" />
          <div className="box">4. 기타 사양서 </div>
          <div className="box" />
          <div className="box">현재 YESBOT Version 0.4 </div>
        </div>
      </div>
    </div>
  );
}

export default Gpt;
